import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { footer_logo } from '../../images';
import "./common.css";
import axios from "axios";

const Footer = () => {

   // Initialize values state
   const [values, setValues] = useState({
    email: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Validation
  if (!values.email.trim()) {
    setErrors({ email: 'Please provide an email' });
    return;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    setErrors({ email: 'Please provide a valid email' });
    return;
  } else {
    setErrors({});
  }

    try {
      await axios.post('https://kweesha.com/subscriber.php', values);
      setSuccessMessage('Thank you for subscribing!');
      setValues({
        email: '',
      });
    } catch (error) {
      console.error('Error posting contact:', error);
    }
  };

  return (
    <>

      <div className='footer_page'>
        <div className="container">
          <footer className="text-center text-lg-start text-dark  p-5" >
            <section className="footer_section">
              <div className="container text-center footerp text-md-start mt-5">
                <div className="row mt-3">
                  <div class="col-md-4 col-lg-3 col-xl-3 col-12 mx-auto mt-3">
                    <img src={footer_logo} alt="kweesha_logo" className='w-75' />
                    <p className="text-dark text-decoration-none mt-4 add"><b>Head Office Address </b><br />
                      16 Navneet Vihar, Sector 9, Indira
                      <br />
                      Nagar, Lucknow, Uttar Pradesh
                      <br />
                      226016
                    </p>
                  </div>
                  <div className="col-md-4 col-lg-2 col-xl-2 col-6 mx-auto s-1 mb-4">
                    <hr className="mb-2 mt-0 d-inline-block mx-auto footer_com" />
                    <h6 className="text-uppercase fw-bold text-success">Quick Link</h6>
                    <hr
                      className="mb-3 mt-1 d-inline-block mx-auto footer_com"
                    />               
                   
                    <p>
                      <Link to="/careers" className="text-dark text-decoration-none p1">Careers</Link>
                    </p>
                    <p>
                      <Link to="/about" className="text-dark text-decoration-none p1">About Us</Link>
                    </p>
                    <p>
                      <Link to="/terms_condition" className="text-dark text-decoration-none p1">Terms Condition</Link>
                    </p>
                    
                    <p>
                      <Link to="/privacy_policy" className="text-dark text-decoration-none p1">Privacy Policy</Link>
                    </p>
                    
                  </div>

                 
                  
                  <div className="col-md-12 col-lg-5 col-xl-5 col-12  mb-4 s-1">
                    <hr
                      className="mb-2 mt-0 d-inline-block mx-auto footer_com"
                    />
                    <h6 className="text-uppercase fw-bold text-success">Subscribe</h6>
                    <hr
                      className="mb-3 mt-1 d-inline-block mx-auto footer_com"
                    />
                    <div class="footer-text mb-25">
                                <p class="text-dark">Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div class="subscribe-form">
                                <form >
                                    <input type="email" name="email" class="text-white" pattern="[^\s@]+@[^\s@]+\.[^\s@]+" placeholder="Email Address" value={values.email}
                                    onChange={e => setValues({...values, email: e.target.value})}/>
                                    <button type="submit" name="submit" onClick={handleSubmit}>Subscribe</button>
                                </form>
                            </div><br></br>
                            <p>{errors.email}</p>
                            <div className="p-2">{successMessage}</div>
                  </div>

                </div>
              </div>
            </section>
            <hr
              className="mb-1 mt-0 d-inline-block mx-auto footer_comm"
            />
            <section className="p-3 pt-0 mt-2 s-2">
              <div className="row d-flex align-items-center">
                <div className="col-md-5 col-lg-7  text-center text-md-start">
                  <div className="p-3 ps-1">
                    Copyright ©<span className='text-success'>2023-2025</span> | <Link to="/" className='text-success text-decoration-none'>Kweesha Pvt. Ltd.</Link>
                  </div>
                </div>

                <div className="col-md-7 col-lg-5 ml-lg-0  text-center text-md-end">
                  <p className='btn text-success btn-floating m-1 '>Follow:</p>

                  <Link to="https://www.instagram.com/kweeshaglobal/?fbclid=IwAR1eC25wnDC18o2yTRATNykB-4dcibdkEiL7k-ku08dp8slH6OMS7LRxkys"
                    className="btn  btn-floating m-1 text-dark icon"
                    role="button"
                  ><i className="bi bi-instagram inst"></i></Link>

                  <Link to="https://www.facebook.com/Kweeshaglobal"
                    className="btn btn-floating m-1 text-dark icon"
                    role="button"
                  ><i className="bi bi-facebook facebook"></i></Link>

                  <Link to="https://www.youtube.com/@Kweeshaglobal"
                    className="btn btn-floating m-1 text-dark icon"
                    role="button"
                  ><i className="bi bi-youtube youtube "></i></Link>
                  <Link to="https://www.linkedin.com/company/kweeshaglobal/"
                    className="btn  btn-floating m-1 text-dark icon"
                    role="button"
                  ><i className="bi bi-linkedin linkdin"></i></Link>
                </div>
              </div>
            </section>
            <hr
              className="mb-1 mt-0 d-inline-block mx-auto footer_comm"
            />
          </footer>
        </div>

      </div>
    </>
  )
}

export default Footer
