import React, { useState } from 'react';
import "./LeaveUs.css";
import { Link } from 'react-router-dom';
import kweesha_home_logo from "../../images/kweesha_home_logo.svg";
import axios from "axios";

const LeaveUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [agree, setAgree] = useState(false);


  const handleFullNameChange = (event) => {
    const value = event.target.value;

    if (!/^[a-zA-Z ]{3,}$/.test(value)) {
      setFullNameError('Please enter a valid full name');
    } else {
      setFullNameError('');
    }

    setName(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;

    setEmail(value);

    if (!value) {
      setEmailError('Please enter your email address');
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };
  

  const handlePhoneChange = (event) => {
    const value = event.target.value;

    setPhone(value);

    if (!value) {
      setPhoneError('Please enter your phone number');
    } else if (!/^[0-9]{10}$/.test(value)) {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
    }
  };

  const handleSubjectChange = (event) => {
    const value = event.target.value;

    setSubject(value);

    if (!value) {
      setSubjectError('Please enter a subject');
    } else {
      setSubjectError('');
    }
  };

  const handleMessageChange = (event) => {
    const value = event.target.value;

    setMessage(value);

    if (!value) {
      setMessageError('Please enter your message');
    } else {
      setMessageError('');
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!name) {
      setFullNameError('Please enter your full name');
    }

    if (!email) {
      setEmailError('Please enter your email address');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address');
    }

    if (!phone) {
      setPhoneError('Please enter your phone number');
    } else if (!/^[0-9]{10}$/.test(phone)) {
      setPhoneError('Please enter a valid phone number');
    }

    if (!subject) {
      setSubjectError('Please enter a subject');
    }

    if (!message) {
      setMessageError('Please enter your message');
    }

    if (name && email && phone && subject && message &&  agree ) {
      try {
        const formData = {
          name,
          email,
          phone,
          subject,
          message,
          agree,
        };
  
        await axios.post("https://kweesha.com/submit-form.php", formData);
        console.log("Message successfully sent");
  
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
        setAgree(false);

        alert(`Dear ${name},\nThank you for your message. We appreciate your interest and will respond to your inquiry as soon as possible.`);
      } catch (error) {
        console.error("Error posting contact:", error);
      }
    }
  };

  return (
    <>
      <div className="leave_us p-5">
        <h1 className='text-center p-4 testscol'>LEAVE US A MESSAGE!</h1>
        <img src={kweesha_home_logo} className='mb-5 mx-auto d-block' alt='kweesha_icon'></img>
        <div className="container">
          <div className="shadow form-container forms-bg  mb-4 mx-auto p-5">
            <form class="row g-3 me-4 mx-4 mt-1" onSubmit={handleFormSubmit}>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Full name *"
                  class={`form-control inputs selct ${fullNameError ? 'is-invalid' : ''}`}
                  id="inputEmail4"
                  name="name"
                  value={name}
                  onChange={handleFullNameChange}
                  onBlur={handleFullNameChange}
                  required
                />
                <div className="invalid-feedback">{fullNameError}</div>
              </div>
              <div class="col-md-6">
                <input
                  type="email"
                  placeholder="Email Address *"
                  class={`form-control inputs selct ${emailError ? 'is-invalid' : ''}`}
                  id="inputPassword4"
                  value={email}
                  name="email"
                  onChange={handleEmailChange}
                  onBlur={handleEmailChange}
                  required
                />
                <div className="invalid-feedback">{emailError}</div>
              </div>
              <div class="col-md-6 mt-4">
                <input
                  type="tel"
                  placeholder="Phone Number *"
                  class={`form-control inputs selct ${phoneError ? 'is-invalid' : ''}`}
                  id="inputPassword4"
                  name="number"
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={handlePhoneChange}
                  required
                />
                <div className="invalid-feedback">{phoneError}</div>
              </div>
              <div class="col-md-6 mt-4">
                <input
                  type="text"
                  placeholder="Subject *"
                  class={`form-control inputs selct ${subjectError ? 'is-invalid' : ''}`}
                  id="inputZip"
                  name="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                  onBlur={handleSubjectChange}
                  required
                />
                <div className="invalid-feedback">{subjectError}</div>
              </div>
              <div class="col-md-12 mt-5">
                <textarea
                  class={`form-control inputs border-grays ${messageError ? 'is-invalid' : ''}`}
                  placeholder="Your Message *"
                  id="form6Example7"
                  rows="4"
                  name="message"
                  value={message}
                  onChange={handleMessageChange}
                  onBlur={handleMessageChange}
                  required
                ></textarea>
                <div className="invalid-feedback">{messageError}</div>
              </div>
              <div class="form-check mb-4">
       <input class="form-check-input custom-checkbox"
      type="checkbox"
      id="flexCheckDefault"
      checked={agree}
      onChange={handleAgreeChange}
      required 
    />
    <label class="form-check-label" for="flexCheckDefault">
      I agree to the <Link to="/terms_condition" className='text-success'>terms & conditions</Link> and <Link to="/privacy_policy" className='text-success'>privacy policy.</Link>
    </label>
  </div>
              <div class="col-md-12 mt-5 ">
                <button
                  type="submit"
                  name="submit"
                  class=" mt-2 mb-5 signup w-25 btn-regi text-center text-white fw-bolder"
                >
                  Submit Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaveUs;